import React, { useEffect, useState } from "react"
import axios from "axios"
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  Label,
  FormGroup,
  Button,
  Input,
  Spinner,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import moment from "moment/moment"
import { toast, ToastContainer } from "react-toastify"
import { FormFeedback } from "reactstrap"
import { getCurrentAcadid } from "store/Common/Actions"
import { useDispatch, useSelector } from "react-redux"
import img1 from './NoAvatar.png';
import { replaceS3Url } from "components/Common/ReplaceS3Url"
const ReusableComponentEmail1 = ({ EmailType, table, details }) => {
  const [formData, setFormData] = useState(details)
  const [errors, setErrors] = useState({})
  const [isChecked, setIsChecked] = useState(false)
  const [emailEdited, setEmailEdited] = useState(false)

  const handleEmailChange = (e, key) => {
    const { value } = e.target

    setFormData({
      ...formData,
      [key]: value,
    })

    if (value.trim() !== "") {
      setEmailEdited(true)
    } else {
      setEmailEdited(false)
    }

    if (key === key) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (!emailRegex.test(value)) {
        setErrors({
          ...errors,
          [key]: "Please enter a valid email address.",
        })
      } else {
        setErrors({
          ...errors,
          [key]: "",
        })
      }
    }
  }

  const handleCheckboxChange = e => {
    setIsChecked(e.target.checked)
  }

  const handleUpdate = async () => {
    try {
      if (isChecked && !errors[EmailType]) {
        console.log("Email updated:", formData["Student Email"])
      }

      const data = {
        sid: localStorage.getItem("sid").replace(/"/g, ""),
        user_id: localStorage.getItem("user_id"),
        tablename: table,
        columns: {
          studemail: formData["Student Email"],
        },
      }

      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/information/update",
        data: data,
      }).then(res => {
        toast.success("Email update successfully!")
        setEmailEdited(false)
      })
    } catch (error) {
      toast.error("Something went wrong!")
    }
  }
  

  return (
    <div className="mb-3">
      {/* <span className="text-primary fs-4 fw-medium">{title}</span> */}
      {Object.entries(formData).map(([key, value]) => (
        <div
          className="d-flex align-items-start justify-content-between"
          key={key}
        >
          <div className="w-50">
            <span
              style={{
                lineHeight: 2,
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {key}
            </span>
          </div>
          <div className="w-70">
            <Input
              type="email"
              style={{
                fontSize: "11px",
              }}
              value={value}
              onChange={e => handleEmailChange(e, key)}
              invalid={errors[key] && true}
            />
            {errors[key] && <FormFeedback>{errors[key]}</FormFeedback>}

            {!errors[key] && emailEdited && (
              <>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />{" "}
                    I want to update the email
                  </Label>
                </FormGroup>

                {isChecked && (
                  <Button
                    color="primary"
                    onClick={handleUpdate}
                    className="mt-2"
                  >
                    Update Email
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
const ReusableComponentEmail2 = ({ EmailType, table, details }) => {
  const [formData, setFormData] = useState(details)
  const [errors, setErrors] = useState({})
  const [isChecked, setIsChecked] = useState(false)
  const [emailEdited, setEmailEdited] = useState(false)

  const handleEmailChange = (e, key) => {
    const { value } = e.target

    setFormData({
      ...formData,
      [key]: value,
    })

    if (value.trim() !== "") {
      setEmailEdited(true)
    } else {
      setEmailEdited(false)
    }

    if (key === EmailType) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (!emailRegex.test(value)) {
        setErrors({
          ...errors,
          [key]: "Please enter a valid email address.",
        })
      } else {
        setErrors({
          ...errors,
          [key]: "",
        })
      }
    }
  }

  const handleCheckboxChange = e => {
    setIsChecked(e.target.checked)
  }

  const handleUpdate = async () => {
    try {
      if (isChecked && !errors[EmailType]) {
      }

      const data = {
        sid: localStorage.getItem("sid").replace(/"/g, ""),
        user_id: localStorage.getItem("user_id"),
        tablename: table,
        columns: {
          email: formData["Father's Email"],
        },
      }
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/information/update",
        data: data,
      }).then(res => {
        toast.success("Email update successfully!")
        setEmailEdited(false)
      })
    } catch (error) {
      console.log(error)
      toast.error("Something went wrong!")
    }
  }

  useEffect(() => {
    console.log(formData)
    console.log(formData["Father's Email"])
  }, [formData])

  return (
    <div className="mb-3">
      {/* <span className="text-primary fs-4 fw-medium">{title}</span> */}
      {Object.entries(formData).map(([key, value]) => (
        <div
          className="d-flex align-items-start justify-content-between"
          key={key}
        >
          <div className="w-50">
            <span
              style={{
                lineHeight: 2,
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {key}
            </span>
          </div>
          <div className="w-70">
            <Input
              type="email"
              style={{
                fontSize: "11px",
              }}
              value={value}
              onChange={e => handleEmailChange(e, key)}
              invalid={errors[key] && true}
            />
            {errors[key] && <FormFeedback>{errors[key]}</FormFeedback>}

            {!errors[key] && emailEdited && (
              <>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />{" "}
                    I want to update the email
                  </Label>
                </FormGroup>

                {isChecked && (
                  <Button
                    color="primary"
                    onClick={handleUpdate}
                    className="mt-2"
                  >
                    Update Email
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

const ReusableComponentEmail3 = ({ EmailType, table, details }) => {
  const [formData, setFormData] = useState(details)
  const [errors, setErrors] = useState({})
  const [isChecked, setIsChecked] = useState(false)
  const [emailEdited, setEmailEdited] = useState(false)

  const handleEmailChange = (e, key) => {
    const { value } = e.target

    setFormData({
      ...formData,
      [key]: value,
    })

    if (value.trim() !== "") {
      setEmailEdited(true)
    } else {
      setEmailEdited(false)
    }

    if (key === EmailType) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (!emailRegex.test(value)) {
        setErrors({
          ...errors,
          [key]: "Please enter a valid email address.",
        })
      } else {
        setErrors({
          ...errors,
          [key]: "",
        })
      }
    }
  }

  const handleCheckboxChange = e => {
    setIsChecked(e.target.checked)
  }

  const handleUpdate = async () => {
    try {
      if (isChecked && !errors[EmailType]) {
      }

      const data = {
        sid: localStorage.getItem("sid").replace(/"/g, ""),
        user_id: localStorage.getItem("user_id"),
        tablename: table,
        columns: {
          memail: formData["Mother's Email"],
        },
      }

      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/information/update",
        data: data,
      }).then(res => {
        toast.success("Email update successfully!")
        setEmailEdited(false)
      })
    } catch (error) {
      toast.error("Something went wrong!")
    }
  }

  return (
    <div className="mb-3">
      {/* <span className="text-primary fs-4 fw-medium">{title}</span> */}
      {Object.entries(formData).map(([key, value]) => (
        <div
          className="d-flex align-items-start justify-content-between"
          key={key}
        >
          <div className="w-50">
            <span
              style={{
                lineHeight: 2,
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {key}
            </span>
          </div>
          <div className="w-70">
            <Input
              type="email"
              style={{
                fontSize: "11px",
              }}
              value={value}
              onChange={e => handleEmailChange(e, key)}
              invalid={errors[key] && true}
            />
            {errors[key] && <FormFeedback>{errors[key]}</FormFeedback>}

            {!errors[key] && emailEdited && (
              <>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />{" "}
                    I want to update the email
                  </Label>
                </FormGroup>

                {isChecked && (
                  <Button
                    color="primary"
                    onClick={handleUpdate}
                    className="mt-2"
                  >
                    Update Email
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

const ReusableComponent = ({ title, details }) => {
  return (
    <div className="mb-3">
      <span className="text-primary fs-4 fw-medium">{title}</span>
      {Object.entries(details).map(([key, value]) => (
        <div
          className="d-flex align-items-start justify-content-between"
          key={key}
        >
          <div className="w-50">
            <span
              style={{
                lineHeight: 2,
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {key}
            </span>
          </div>
          <div className="w-50">
            <span style={{ lineHeight: "2", fontSize: "14px" }}>{value}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

const ReusableComponent10 = ({
  title,
  tablename,
  profile,
  bucketName,
  setSubmitted,
  details,
}) => {
  const [avatar12, setAvatar12] = useState()
  const [loading, setLoading] = useState(false)

  const handleFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      console.log("Selected file:", file)
      setAvatar12(file)
    }
  }

  const handleImageSubmit = async e => {
    e.preventDefault()
    let formData = new FormData()
    formData.append("sid", localStorage.getItem("sid").replace(/"/g, ""))
    formData.append("user_id", localStorage.getItem("user_id"))
    formData.append("tablename", tablename)
    formData.append("bucketName", bucketName)
    formData.append("profile", "avatar")
    // formData.append("image", image);
    formData.append("image", avatar12)

    setSubmitted(false)
    setLoading(true)
    await axios({
      method: "POST",
      url: `https://marcopolo.prisms.in/api/information/changepic`,
      data: formData,
      headers: {
        Authorization: "#$laksdfnlknoea#$@$%^&%$",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(response => {
        setAvatar12(null)
        setLoading(false)
        setSubmitted(true)
        toast.success("Profile Successfully updated")
      })
      .catch(error => {
        console.log(error)
        setLoading(false)

        setSubmitted(true)
      })
  }

  return (
    <div className="mb-3 mt-4">
      {Object.entries(details).map(([key, value]) => (
        <div
          className="d-flex align-items-start justify-content-between mt-5"
          key={key}
        >
          <div className="w-50">
            <span
              style={{
                lineHeight: 2,
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {key}
            </span>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <div className="w-50">
              <div className="position-relative">
                <CardImg
                  src={
                    avatar12
                      ? URL.createObjectURL(avatar12)
                      : value ? value
                        : img1
                  }
                  alt="Profile"
                  className="rounded-circle"
                  style={{
                    width: "120px",
                    height: "120px",
                    marginLeft: "13px",
                    objectFit: "cover",
                    border: "4px solid #ccc",
                  }}
                />

                <Input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  style={{ display: "none" }}
                  id="file-input-12"
                  onChange={handleFileChange}
                />

                <label
                  htmlFor="file-input-12"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: "91px",
                    backgroundColor: "#007bff",
                    color: "white",
                    padding: "5px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    border: "2px solid white",
                  }}
                >
                  <i className="fa fa-camera" style={{ fontSize: "18px" }} />
                </label>
              </div>
              {avatar12 ? (
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-outline-primary mt-3"
                    onClick={handleImageSubmit}
                  >
                    Upload
                  </button>
                </div>
              ) : null}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
const ReusableComponent2 = ({
  title,
  tablename,
  profile,
  bucketName,
  setSubmitted,
  details,
}) => {
  const [avatar, setAvatar] = useState()
  const [loading, setLoading] = useState(false)

  const handleFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      console.log("Selected file:", file)
      setAvatar(file)
    }
  }

  const handleImageSubmit = async e => {
    e.preventDefault()
    let formData = new FormData()
    formData.append("sid", localStorage.getItem("sid").replace(/"/g, ""))
    formData.append("user_id", localStorage.getItem("user_id"))
    formData.append("tablename", tablename)
    formData.append("bucketName", bucketName)
    formData.append("profile", "app_avatar_father")
    // formData.append("image", image);
    formData.append("image", avatar)

    setSubmitted(false)
    setLoading(true)
    await axios({
      method: "POST",
      url: `https://marcopolo.prisms.in/api/information/changepic`,
      data: formData,
      headers: {
        Authorization: "#$laksdfnlknoea#$@$%^&%$",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(response => {
        setAvatar(null)
        setLoading(false)
        setSubmitted(true)
        toast.success("Profile Successfully updated")
      })
      .catch(error => {
        setLoading(false)
        setSubmitted(false)
      })
  }

  return (
    <div className="mb-3 mt-4">
      {Object.entries(details).map(([key, value]) => (
        <div
          className="d-flex align-items-start justify-content-between mt-5"
          key={key}
        >
          <div className="w-50">
            <span
              style={{
                lineHeight: 2,
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {key}
            </span>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <div className="w-50">
              <div className="position-relative">
                <CardImg
                  src={
                    avatar
                      ? URL.createObjectURL(avatar)
                      : value ||
                        img1
                  }
                  alt="Profile"
                  className="rounded-circle"
                  style={{
                    width: "120px",
                    height: "120px",
                    marginLeft: "13px",
                    objectFit: "cover",
                    border: "4px solid #ccc",
                  }}
                />

                <Input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  style={{ display: "none" }}
                  id="file-input"
                  onChange={handleFileChange}
                />

                <label
                  htmlFor="file-input"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: "91px",
                    backgroundColor: "#007bff",
                    color: "white",
                    padding: "5px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    border: "2px solid white",
                  }}
                >
                  <i className="fa fa-camera" style={{ fontSize: "18px" }} />
                </label>
              </div>
              {avatar ? (
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-outline-primary mt-3"
                    onClick={handleImageSubmit}
                  >
                    Upload
                  </button>
                </div>
              ) : null}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

const ReusableComponent3 = ({
  title,
  tablename,
  profile,
  bucketName,
  setSubmitted,
  details,
}) => {
  const [avatar1, setAvatar1] = useState()
  const [loading, setLoading] = useState(false)

  const handleFileChange1 = e => {
    const file = e.target.files[0]
    if (file) {
      console.log("Selected file:", file)
      setAvatar1(file)
    }
  }

  const handleImageSubmit = async e => {
    e.preventDefault()
    let formData = new FormData()
    formData.append("sid", localStorage.getItem("sid").replace(/"/g, ""))
    formData.append("user_id", localStorage.getItem("user_id"))
    formData.append("tablename", tablename)
    formData.append("bucketName", bucketName)
    formData.append("profile", "app_avatar_mother")
    // formData.append("image", image);
    formData.append("image", avatar1)
    setSubmitted(false)
    setLoading(true)

    await axios({
      method: "POST",
      url: `https://marcopolo.prisms.in/api/information/changepic`,
      data: formData,
      headers: {
        Authorization: "#$laksdfnlknoea#$@$%^&%$",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(response => {
        setAvatar1(null)
        setLoading(false)
        setSubmitted(true)
        toast.success("Profile Successfully updated")
      })
      .catch(error => {
        setLoading(false)
        setSubmitted(true)
      })
  }
  return (
    <div className="mb-3 mt-4">
      {/* <span className="text-primary fs-4 fw-medium">{title}</span> */}
      {Object.entries(details).map(([key, value]) => (
        <div
          className="d-flex align-items-start justify-content-between mt-5"
          key={key}
        >
          <div className="w-50">
            <span
              style={{
                lineHeight: 2,
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {key}
            </span>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <div className="w-50">
              <div className="position-relative">
                <CardImg
                  src={
                    avatar1
                      ? URL.createObjectURL(avatar1)
                      : value ||
                        img1
                  }
                  alt="Profile"
                  className="rounded-circle"
                  style={{
                    width: "120px",
                    height: "120px",
                    marginLeft: "13px",
                    objectFit: "cover",
                    border: "4px solid #ccc",
                  }}
                />

                <Input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  style={{ display: "none" }}
                  id="file-input-2"
                  onChange={handleFileChange1}
                />

                <label
                  htmlFor="file-input-2"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: "91px",
                    backgroundColor: "#007bff",
                    color: "white",
                    padding: "5px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    border: "2px solid white",
                  }}
                >
                  <i className="fa fa-camera" style={{ fontSize: "18px" }} />
                </label>
              </div>
              {avatar1 ? (
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-outline-primary mt-3"
                    onClick={handleImageSubmit}
                  >
                    Upload
                  </button>
                </div>
              ) : null}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

const Home = () => {
  const dispatch = useDispatch()
  const [submitted, setSubmitted] = useState(false)

  const { acadID } = useSelector(state => state.CommonReducer)
  const token = JSON.parse(localStorage.getItem("Token"))
  const [profileData, setProfileData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [schoolName, setSchoolName] = useState()

  useEffect(() => {
    const getTeacherProfile = async () => {
      try {
        const res = await axios({
          method: "POST",
          data: {
            sid: localStorage.getItem("sid").replace(/"/g, ""),
            userid: localStorage.getItem("user_id"),
          },
          url: "https://marcopolo.prisms.in/api/studentProfile/data",
        })

        setProfileData(res.data)
        setLoading(false)
      } catch (error) {
        setError("Failed to load profile data. Please try again.")
        setLoading(false)
      }
    }

    const getSchoolName = async () => {
      const data = {
        fun_name: "getSchoolURL",
        sid: localStorage.getItem("sid").replace(/"/g, ""),
      }
      await axios({
        method: "POST",
        url: "https://topschool.prisms.in/rest/index.php/user_list.json",
        data,
      }).then(response => {
        console.log(response.data)
        setSchoolName(response.data)
      })
    }
    getTeacherProfile()
    getSchoolName()

    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
  }, [token, submitted])

  const replaceS3Url = url => {
    const baseUrl = "https://files.prisms.in/"
    const replacementBaseUrl = "https://s3.amazonaws.com/topschool-attachments/"

    const newUrl = url.replace(replacementBaseUrl, baseUrl)
    return newUrl
  }

  return (
    <div className="page-content">
      {loading && <Spinners />}
      {error && <div className="alert alert-danger">{error}</div>}

      {!loading && !error && profileData.length > 0 && (
        <Card className="shadow-lg mx-auto" style={{ maxWidth: "900px" }}>
          <CardBody>
            <div className="text-center mb-3 mt-3">
              <CardImg
                src={replaceS3Url(profileData[0].avatar) || img1}
                alt="Profile"
                className="rounded-circle"
                style={{ width: "120px", height: "120px", objectFit: "cover" }}
              />
              <h5 className="text-center text-dark mt-4">
                {profileData && profileData[0].Student_name}
              </h5>
            </div>
            <ReusableComponent
              title="General Details"
              details={{
                Name: profileData[0].Student_name,
                Username: profileData[0].username,
                Class: profileData[0].class_name,
                Batch: acadID.academics,
              }}
            />
            <ReusableComponent
              title="Personal Details"
              details={{
                "Date of Birth": moment(profileData[0].dob).format(
                  "DD-MM-YYYY"
                ),
                "Father's Name": profileData[0].father_name,
                "Mother's Name": profileData[0].mother_name,
              }}
            />

            <ReusableComponent10
              details={{
                "Student Pic": profileData[0].avatar,
              }}
              profile={"student"}
              bucketName={`topschool-attachments/${schoolName}prisms/${acadID.acadYear}/studentProfile/student`}
              tablename={"k6kb9_pri_students_static_info"}
              setSubmitted={setSubmitted}
            />
            <ReusableComponent2
              details={{
                "Father's Pic": replaceS3Url(profileData[0].app_avatar_father),
              }}
              profile={"father"}
              bucketName={`topschool-attachments/${schoolName}prisms/${acadID.acadYear}/studentProfile/father`}
              tablename={"k6kb9_pri_parents_info"}
              setSubmitted={setSubmitted}
            />
            <ReusableComponent3
              details={{
                "Mother's Pic": replaceS3Url(profileData[0].app_avatar_mother),
              }}
              profile={"mother"}
              bucketName={`topschool-attachments/${schoolName}prisms/${acadID.acadYear}/studentProfile/mother`}
              tablename={"k6kb9_pri_parents_info"}
              setSubmitted={setSubmitted}
            />
            <ReusableComponent
              title="Address"
              details={{
                "Address line 1": profileData[0].paddressl1,
                "Address line 2": profileData[0].paddressl2,
                State: profileData[0].pstate,
                City: profileData[0].pcity,
                Pin: profileData[0].ppin,
              }}
            />
            <ReusableComponent
              title="Contact"
              details={{
                "Mobile 1": profileData[0].mobile1,
                "Mobile 2": profileData[0].mobile2,
              }}
            />

            <ReusableComponentEmail1
              title="Email"
              EmailType="Student Email"
              table={"k6kb9_pri_students_static_info"}
              details={{
                "Student Email": profileData[0].studemail,
              }}
            />
            <ReusableComponentEmail2
              title="Email"
              EmailType="Father's Email"
              table={"k6kb9_pri_parents_info"}
              details={{
                "Father's Email": profileData[0].email,
              }}
            />

            <ReusableComponentEmail3
              title="Email"
              EmailType="Mother's Email"
              table={"k6kb9_pri_parents_info"}
              details={{
                "Mother's Email": profileData[0].memail,
              }}
            />
          </CardBody>
        </Card>
      )}
      <ToastContainer />
    </div>
  )
}

export default Home
