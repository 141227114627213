import React, { useState, useEffect } from "react"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import moment from "moment/moment"
import { Card, CardBody, Container,Row,Col } from "reactstrap"
const CardD = ({ title, value }) => {
  return (
    <Col xs={12} md={6}>
      <div className="d-flex align-items-start">
        <p className="fw-bold text-dark fw-5 mb-0 me-2 w-50">{title}:</p>
        <p className="mb-0 w-50 fw-5 text-dark">{value}</p>
      </div>
    </Col>
  )
}
const ResuableComponent = ({ data }) => {
  if (!data || data === "INVALID") {
    return (
      <Card>
        <CardBody className="text-center">
          <span className="text-center text-dark mt-2 mb-3 fs-6">
            Class Test Result Not Available
          </span>
        </CardBody>
      </Card>
    )
  }
  return (
    <div>
      {data &&
        data !== "INVALID" &&
        data.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                backgroundColor: "#fff",
                borderRadius: "4px",
                marginTop: "5px",
                padding: "10px",
                position: "relative",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="d-flex justify-content-between">
                <div className="w-30">
                  <div className="d-flex flex-column">
                    <span className="mt-1 text-start fs-4 text-dark fw-bold">
                      Date
                    </span>
                    <span className="mt-1 text-start fs-4 text-dark fw-bold">
                      Subject
                    </span>
                    <span className="mt-1 text-start fs-4 text-dark fw-bold">
                      Topic Name
                    </span>
                    <span className="mt-1 text-start fs-4 text-dark fw-bold">
                      Max.Marks{" "}
                    </span>
                    <span className="mt-1 text-start fs-4 text-dark fw-bold">
                      Marks Obtained{" "}
                    </span>
                  </div>
                </div>
                <div className="w-70">
                  <div className="d-flex flex-column">
                    <span className="mt-1 text-start fs-4 text-dark">
                      {item.date}
                    </span>
                    <span className="mt-1 text-start fs-4 text-dark">
                      {item.subject}
                    </span>
                    <span className="mt-1 text-start fs-4 text-dark">
                      {item.topic}
                    </span>
                    <span className="mt-1 text-start fs-4 text-dark">
                      {item.max}
                    </span>
                    <span className="mt-1 text-start fs-4 text-dark">
                    {/* {(item.obtained === "9999" || item.obtained === "9998") ? "" : item.obtained} */}
                    {item.obtained === "9999" ? "NA" : item.obtained === "9998" ? "AB" : item.obtained}

                    </span>
                  </div>
                </div>
              </div>
              {/* <Card title="Date" value={item.date} /> */}
            </div>
          )
        })}
    </div>
  )
}
const Report = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const token = JSON.parse(localStorage.getItem("Token"))

  const getClassTestReport = async () => {
    setLoading(true)
    try {
      await axios({
        method: "POST",
        url: "https://topschool.prisms.in/rest/index.php/user_list.json?",
        data: {
          fun_name: "get_ClassTest_Data_For_IOS",
          sid: localStorage.getItem("sid").replace(/"/g, ""),
          userid: localStorage.getItem("user_id"),
        },
      }).then(res => {
        console.log(res.data)
        setData(res.data)
        setLoading(false)
      })
    } catch (error) {
      console.error("Error fetching leave data:", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getClassTestReport()
  }, [])

  return (
    <>
      <div className="page-content">
        <Container>
        <Card className="shadow-lg rounded-lg">
            <CardBody>
            <h1 className="text-center mb-4 fs-3" style={{ color: "#3c3c3c" }}>
            Class Test Result
            </h1>
            <Row className="mb-4">
              <Col xl="12" md="12" sm="12">
              {loading ? <Spinners /> : <ResuableComponent data={data || []} />}

              </Col>
            </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Report
