import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  CardImg,
  Input,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentAcadid } from "store/Common/Actions"
import RSelect from "react-select"

const Home = () => {
  const dispatch = useDispatch()
  const baseUrl = "https://marcopolo.prisms.in/api/examfillmarks"

  const { acadID } = useSelector(state => state.CommonReducer)
  const token = JSON.parse(localStorage.getItem("Token"))
  const sid = localStorage.getItem("sid")

  const [studentList, setStudentList] = useState([])
  const [academic, setAcademic] = useState([])
  const [acad_id, setAcad_id] = useState("")
  const [div, setDiv] = useState([])
  const [studentId, setStudentId] = useState("")
  const [div_id, setDiv_id] = useState("")
  const [termData, setTermData] = useState([])
  const [term_id, setTerm_id] = useState("")
  const [loading, setLoading] = useState(false)

  const [stud, setStud] = useState({
    name: "",
    pic: "",
  })
  const [remarkData, setRemarkData] = useState([])

  const getAcademicYear = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: `${baseUrl}/get-Academic-year`,
        headers: {
          Authorization: token,
        },
      })
      setAcademic(res.data.data)
    } catch (error) {
      console.log("Error fetching academic year:", error)
    }
  }

  useEffect(() => {
    if (sid) {
      dispatch(
        getCurrentAcadid({
          sid,
        })
      )
    }
    getAcademicYear()
  }, [sid, dispatch])

  const getClassDiv = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: `${baseUrl}/get-classdiv-list`,
        data: {
          acad_id: acad_id || acadID.acadid,
        },
        headers: {
          Authorization: token,
        },
      })
      setDiv(res.data.data)
    } catch (error) {
      console.log("Error fetching class divisions:", error)
    }
  }

  useEffect(() => {
    if (acad_id || acadID.acadid) {
      getClassDiv()
    }
  }, [acad_id, acadID.acadid])

  useEffect(() => {
    const getAllStudents = async () => {
      try {
        const response = await axios({
          method: "POST",
          url: `https://marcopolo.prisms.in/api/tscommon/getstudentdetails`,
          data: { sid, division: div_id },
        })
        setStudentList(response.data)
      } catch (error) {
        console.error("Error fetching students:", error)
      }
    }
    if (div_id) {
      getAllStudents()
    }
  }, [div_id, sid])

  useEffect(() => {
    const getTerm = async () => {
      setLoading(true)
      try {
        const res = await axios({
          method: "GET",
          url: `https://marcopolo.prisms.in/api/remark/select-term`,
          headers: {
            Authorization: token,
          },
        })
        setTermData(res.data.data)
        setLoading(false)
      } catch (error) {
        console.log("Error fetching terms:", error)
        setLoading(false)
      }
    }
    getTerm()
  }, [])

  const handleStud = e => {
    const { user_id, name, avatar } = JSON.parse(e.target.value)
    setStudentId(user_id)
    setStud(prev => ({
      ...prev,
      name: name,
      avatar: avatar,
    }))
  }

  useEffect(() => {
    const getFillRemark = async () => {
      try {
        const response = await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/remark/fill-remark",
          data: {
            acad_id: acad_id || acadID.acadid,
            user_id: [studentId],
            term_id: term_id,
            classdiv_id: div_id,
          },
          headers: {
            Authorization: token,
          },
        })

        const r1 = response.data.data

        setRemarkData(r1)
      } catch (error) {
        console.error("Error fetching fill remark:", error)
      }
    }

    if (acad_id || (acadID.acadid && div_id && term_id && studentId)) {
      getFillRemark()
    }
  }, [acadID.acadid, acad_id, div_id, term_id, studentId])

  // const t =
  //   remarkData.length > 0 && remarkData[0]?.remarkResult?.length > 0
  //     ? remarkData[0].remarkResult.map(item => {
  //         return {
  //           label: item.remark_type,
  //           options: {
  //             grouped_remarks: item.grouped_remarks,
  //           },
  //         }
  //       })
  //     : []

  const t =
    remarkData.length > 0 && remarkData[0]?.remarkResult?.length > 0
      ? remarkData[0].remarkResult.map(item => ({
          label: item.remark_type,
          options: item.grouped_remarks.map(remark => {
            const value = remark.split(":")[1]?.trim()
            return {
              label: value,
              value: value,
            }
          }),
        }))
      : []

  return (
    <div className="page-content">
      <Container>
        <Card className="shadow-lg rounded-lg">
          <CardBody>
            <h1 className="text-center mb-4 fs-3" style={{ color: "#3c3c3c" }}>
              Fill Remarks
            </h1>

            {/* Academic Year */}
            <Row className="mb-4">
              <Col xl="12">
                <select
                  className="form-select"
                  onChange={e => setAcad_id(e.target.value)}
                >
                  <option value={acadID.acadid}>{acadID.academics}</option>
                  {academic.map(year => (
                    <option key={year.acadid} value={year.acadid}>
                      {year.academics}
                    </option>
                  ))}
                </select>
              </Col>

              {/* Term Dropdown */}
              <Col xl="12" className="mt-3">
                <select
                  className="form-select"
                  onChange={e => setTerm_id(e.target.value)}
                >
                  <option>Select Term</option>
                  {termData.map(item => (
                    <option key={item.id} value={item.termid}>
                      {item.termname}
                    </option>
                  ))}
                </select>
              </Col>

              {/* Division Dropdown */}
              <Col xl="12" className="mt-3">
                <select
                  className="form-select"
                  onChange={e => setDiv_id(e.target.value)}
                >
                  <option>Select Division</option>
                  {div.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.div_name}
                    </option>
                  ))}
                </select>
              </Col>

              {/* Student Dropdown */}
              <Col xl="12" className="mt-3">
                <select className="form-select" onChange={handleStud}>
                  <option>Select Student</option>
                  {studentList &&
                    studentList.map(item => (
                      <option
                        key={item.id}
                        value={JSON.stringify({
                          user_id: item.user_id,
                          name: item.fname + " " + item.lname,
                          avatar: item.avatar,
                        })}
                      >
                        {item.fname + " " + item.lname}
                      </option>
                    ))}
                </select>
              </Col>
            </Row>

            <Col xl="12" className="mt-3">
              {studentId && (
                <Card className="shadow-sm rounded ">
                  <div className="text-center mb-3 mt-3">
                    <CardImg
                      alt="Profile"
                      className="rounded-circle"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                      src={
                        stud.avatar
                          ? stud.avatar
                          : "https://ocisa.prisms.in/images/dashboard/student.png"
                      }
                    />
                  </div>
                  <span className="text-center text-dark fw-medium mb-3">
                    {stud.name}
                  </span>
                </Card>
              )}
            </Col>

            <Row>
              <Row>
                {t &&
                  t.map(section => (
                    <Col xl="12" key={section.label} className="mt-4">
                      <p className="fw-medium fs-6">{section.label}</p>

                      <RSelect
                        options={section.options}
                        isMulti
                        placeholder={`Select ${section.label} Remarks`}
                      />
                      <Input type="textarea" className="mt-3" placeholder="type your remark here"/>
                    </Col>
                  ))}
              </Row>
            </Row>

            <Col className="d-flex justify-content-center align-items-center mt-3">
              <button className="btn btn-primary">Submit</button>
            </Col>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Home
