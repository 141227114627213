import React, { useEffect, useState } from "react"
import { Card, CardBody, Button, Row, Col, Alert, Badge } from "reactstrap"
import moment from "moment"
import axios from "axios"

function CardComponent({ Data, acadId, sid, uid }) {
  const [cid, setCid] = useState()
  const live_Url = "https://marcopolo.prisms.in"

  const [schName, setSchoolName] = useState()
  const [studName, setStud] = useState()
  const token = localStorage.getItem("Token").replace(/"/g, "")
  const fun_term = "showStudScolasticReportcbsecce"
  const fun_Exam = "showStudReportcbsecce"

  useEffect(() => {
    const getClassDiv = async () => {
      try {
        await axios({
          method: "POST",
          url: `${live_Url}/api/tscommon/division_id`,
          data: { acad_id: acadId },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setCid(res.data[0].classdiv_id)
        })
      } catch (error) {
        console.log(error)
      }
    }
    getClassDiv()

    const getStudentName = async () => {
      const data = {
        sid: sid,
        stud_id: uid,
      }
      await axios({
        method: "POST",
        url: `${live_Url}/api/tscommon/student-name`,
        data,
      }).then(response => {
        // console.log(response.data)
        setStud(response.data[0].student_name)
      })
    }
    getStudentName()

    const getSchoolName = async () => {
      const data = {
        fun_name: "getSchoolURL",
        sid: sid,
      }
      await axios({
        method: "POST",
        url: "https://topschool.prisms.in/rest/index.php/user_list.json",
        data,
      }).then(response => {
        setSchoolName(response.data)
      })
    }
    getSchoolName()
  }, [sid, uid])
  const handleDownloadClick = async (fun, exam, id) => {
    const message = JSON.stringify({
      action: "ReportCardHome",
      fun: fun,
      id: id,
      sch: schName,
      cid: cid,
      acad: acadId,
      studName: studName,
      exam: exam,
    })
    window.ReactNativeWebView?.postMessage(message)
  }

  return (
    <div>
      {Data.exam_details &&
      Data.exam_details.length === 0 &&
      Data.term_details &&
      Data.term_details.length === 0 ? (
        <Alert
          color="danger"
          className="text-center mt-4"
          style={{
            fontFamily: "Poppins",
            // fontWeight: "bold",
            fontSize: "xl",
          }}
        >
          Report not available
        </Alert>
      ) : (
        <>
          {Data.exam_details?.map((data, idx) => (
            <Card key={idx} className="p-4">
                <Row className="d-flex justify-content-between align-items-center">
                  <Col>
                    <h5
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "blue",
                      }}
                    >
                      {data.exam_name} - (exam wise report card)
                    </h5>
                  </Col>
                </Row>
              <Col className="d-flex justify-content-between align-items-center">
              <p
                  style={{
                    // marginTop: "10px",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                >
                  Published On {moment(data.created_date).format("DD-MM-YYYY")}
                </p>
                <Badge
                  color="success"
                  onClick={() =>
                    handleDownloadClick(fun_Exam, data.exam_name, data.id)
                  }
                >
                  Download
                </Badge>
              </Col>
            </Card>
          ))}

          {Data.term_details?.map((data, idx) => (
            <Card key={idx} className="p-4">
                <Row className="d-flex justify-content-between align-items-center">
                  <Col>
                    <h5
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        color: "blue",
                        fontSize: "14px",
                      }}
                    >
                      Term {data.termid} - (wise report card)
                    </h5>
                  </Col>
                </Row>
              
                <div className="d-flex justify-content-between align-items-center">
                <p
                  style={{
                    // marginTop: "10px",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                >
                  Published On {moment(data.created_date).format("DD-MM-YYYY")}
                </p>
                  <Badge
                    color="success"
                    onClick={() =>
                      handleDownloadClick(fun_term, data.termid, data.termid)
                    }
                  >
                    Download
                  </Badge>
                </div>
            </Card>
          ))}
        </>
      )}
    </div>
  )
}

export default CardComponent
