import React, { useState, useEffect } from "react"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import { Card, CardBody, Col, Container, Input, Row, Spinner } from "reactstrap"
import moment from "moment"

const LogReport = () => {
  const [logData, setLogData] = useState([])

  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState()
  const token = JSON.parse(localStorage.getItem("Token"))

  const url = "https://marcopolo.prisms.in/"
  const getLogReport = async () => {
    try {
      setLoading(true)
      await axios({
        method: "POST",
        url: `${url}api/logsheet/view-logsheets`,
        data: { date: moment(date).format("DD-MM-YYYY") },
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setLoading(false)
        console.log(res.data)
        setLogData(res.data.data)
      })
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0")
    const day = String(currentDate.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    setDate(formattedDate)
  }, [])

  useEffect(() => {
    if (date) {
      getLogReport()
    }
  }, [date])

  return (
    <div className="page-content">
      <Container className="">
        <Card className="shadow-lg rounded-lg">
          <CardBody>
            <h1 className="text-center mb-4 fs-3" style={{ color: "#3c3c3c" }}>
              Log Sheet Report
            </h1>

            <Row className="mb-4">
              <Col xl="12" md="12" sm="12">
                <Input
                  type="date"
                  value={date}
                  onChange={e => setDate(e.target.value)}
                  className="form-control"
                  style={{ borderRadius: "4px", padding: "10px" }}
                />
              </Col>
            </Row>

            {loading ? (
              <Spinners />
            ) : (
              <Row>
                {logData &&
                  logData.map((log, index) => (
                    <Col
                      key={index}
                      xl="12"
                      lg="6"
                      md="6"
                      sm="12"
                      className="mb-4"
                    >
                      <Card className="shadow-lg rounded-lg">
                        <CardBody>
                          <h5
                            className="font-weight-bold mb-2"
                            style={{ color: "#0056b3" }}
                          >
                            {log.lecturename}
                          </h5>

                          <p className="d-flex aling-item-center justify-content-between">
                            <strong>Division:</strong> {log.class_name}
                          </p>

                          <p className="d-flex aling-item-center justify-content-between">
                            <strong>Subject:</strong> {log.subject}
                          </p>
                          <p className="d-flex aling-item-center justify-content-between">
                            <strong>Date:</strong> {log.sdate}
                          </p>
                          <p className="d-flex aling-item-center justify-content-between">
                            <strong>Arrival:</strong> {log.arrive_time}
                          </p>
                          <p className="d-flex aling-item-center justify-content-between">
                            <strong>Departure:</strong> {log.departure_time}
                          </p>
                          <p className="d-flex aling-item-center justify-content-between">
                            <strong>Topic/Sub-Topic:</strong>{" "}
                            {log.topic_covered}
                          </p>
                          <p className="d-flex aling-item-center justify-content-between">
                            <strong>Procedure/Methodology:</strong>{" "}
                            {log.methodology}
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
              </Row>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default LogReport
