import React from "react"
import { Navigate } from "react-router-dom"

// PRISMS MODULE
import NotificationCenterPrisms from "pages/PrismsModule/NotificationCenter/Home"
//

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// STUDENT LEAVE REPORT FOR TEACHER APP :
import StudentLeaveReportTeacher from "../pages/TeacherApp/LeaveApp/StudentLeaveReport"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import NewNotifiationCenter from "../pages/TeacherApp/NotificationCenterNew/Home"
// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Comingsoon from "components/Common/CommingSoon"
import ExternalLinks from "pages/ExternalLinks"
import Notifications from "pages/Notifications/View"
import FeedBack from "pages/Feedback"
import CreateFeedback from "pages/Feedback/CreateFeedback"
import ChatWindow from "pages/Feedback/ChatWindow"
import MediaView from "components/Common/MediaView"
import HandleRoutes from "pages/HandleRoutes"
import CreateNotifications from "pages/Notifications/Create"
import ViewTimeTable from "pages/TimeTable/View"
import PaidFeesDetails from "pages/Fees/PaidFeesDetails"
import PrintRecipt from "pages/Fees/PaidFeesDetails/PrintRecipt"
import Attendance from "pages/Attendance/Teacher"
import Gallery from "pages/Gallery"
import ImageGallery from "pages/Gallery/ImageGallery"

// Exam Fill Marks for Teacher

import ExamFillMarker from "pages/TeacherApp/FillexamMarks/Home"
import ClassTestMarks from "pages/TeacherApp/ClassTestMarks/Card"
import SingleClassTest from "pages/TeacherApp/ClassTestMarks/MarksOfStud"
// Access Subject Report

import AccessHome from "pages/TeacherApp/SubjectAccess/SubjectHome"
import SubjectAccess from "pages/TeacherApp/SubjectAccess/Home"
import SubjectAccessReport from "pages/TeacherApp/SubjectAccess/Report"

// TEACHER APP - Routes
// Leave Apply for Teacher
import TeacherLeaveApp from "pages/TeacherApp/LeaveApp/Home"
import LeaveRequest from "pages/TeacherApp/LeaveApp/ApplyLeave"
import LeaveHistory from "pages/TeacherApp/LeaveApp/LeaveReport"
import StudentLeave from "pages/Report/LeaveReport"
import StudentLeaveHome from "pages/STUDENT/StudentLeave/Home"
// Teacher Pofile App
import TeacherProfile from "pages/TeacherApp/TeacherProfile/Home"

// Visitor App
import VisitorApp from "pages/TeacherApp/Visitor/Home"
import VisiorManullay from "pages/TeacherApp/Visitor/Manullay"
import Visitors from "pages/TeacherApp/Visitor/Checkout"
import VisitorScanner from "pages/TeacherApp/Visitor/NewScanner"

import CheckoutHome from "pages/TeacherApp/Visitor/CheckoutHome"
// Visitor App
import AttendanceReport from "pages/Attendance/Teacher/Report"

// Calendar App
import DocHome from "pages/STUDENT/Doc/Home"
import DocRepo from "pages/STUDENT/Doc/SingleView"
import DocSub from "pages/STUDENT/Doc/SubFolder"
import CalendarHome from "pages/TeacherApp/Calendar/Home"

// STUDENT PROFILE :
import StudentLeaveReport from "pages/STUDENT/StudentLeave/LeaveReport"
import StudentProfile from "pages/TeacherApp/StudentProfile/Home"
import StudentProfileApp from "pages/STUDENT/ProfileApp/Home"

import StudentPortfolioReport from "pages/STUDENT/Portfolio/Student/Home"
import StudentPortfolioDownload from "pages/STUDENT/Portfolio/Student/Report"
import TeacherPortfolioReport from "pages/TeacherApp/Portfolio/Report"
import CreatePortfolio from "pages/TeacherApp/Portfolio/Form"

import AssignmentDeatils from "pages/STUDENT/Assignment/AssignmentDeatils"
import SubmitAssignment from "pages/STUDENT/Assignment/Submit"
import SubmittedAssignmentDeatils from "pages/STUDENT/Assignment/SubmittedReport"
import SubmitRemark from "pages/TeacherApp/Assignment/Remark"
// Assignment
import AssigmentHome from "pages/TeacherApp/Assignment/Home"
import CreateAssignment from "pages/TeacherApp/Assignment/CreateAssignment"
import AssignmentReport from "pages/TeacherApp/Assignment/Report"
import ViewAssignmentStundent from "pages/TeacherApp/Assignment/ViewSingleAssingment"
import StudentsAssignment from "pages/TeacherApp/Assignment/StudentAssignment"
import ReportCard from "pages/STUDENT/ReportCard/Home"
import ReportResult from "pages/STUDENT/ReportCard/ReportCard"

import StudentAssignmentHome from "pages/STUDENT/Assignment/Home"
// Fees :
import DownloadFees from "pages/Fees/PaidFeesDetails/DownloadRecipt"
import ClassTestReport from "pages/STUDENT/ClassTest/Home"

import Fees from "pages/TeacherApp/FeesReport/Home"
import NotesUpload from "pages/TeacherApp/NotesUpload/Home"
import FeesDetails from "pages/TeacherApp/FeesReport/Report"

// Student App
import StudentNotes from "pages/STUDENT/NotesApp/Notes"
import StudentLeaveApp from "pages/STUDENT/StudentLeave/Home"
import StudentLeaveApply from "pages/STUDENT/StudentLeave/ApplyLeave"
// import StudentLeaveReport  from "pages/STUDENT/StudentLeave/LeaveReport"
// Gallery
import TestApp from "pages/TeacherApp/Test/Home"
import GalleryStud from "pages/STUDENT/GalleryApp/Home"
import ListOfImages from "pages/STUDENT/GalleryApp/ImageList"

import OnlineFess from "pages/STUDENT/OnlinePayment/Home"
import Testing from "pages/TeacherApp/Test/Testing"
import T1 from "pages/TeacherApp/Test/Test"
import SM from "pages/TeacherApp/Visitor/ScanM"
// Log Sheet
import LogSheet from "pages/TeacherApp/LogS/Home"
import LogSheetDay from "pages/TeacherApp/LogS/LogSheetId"
import LogsheetTimetable from "pages/TeacherApp/LogS/TimeTable"
import LogSheetSubmit from "pages/TeacherApp/LogS/SubmitLog"
import LogSheetReport from "pages/TeacherApp/LogS/LogReport"
import Remark from "pages/TeacherApp/Assignment/Remark"

// Teacher Remark Module:

import RemarkHome from "pages/TeacherApp/Remark/Home"

import Holidays from "pages/STUDENT/Holiday/Home"
// ADMIN APP ROUTES : -

// 1. SCHEDULE NOTIFICATION - /admin/schedule/
//s

import ScheduleNotification from "pages/Admin/ScheduleNotification/Home"

// 2. REVIEW NOTIFICATION - /admin/review/notifications
import ReviewNotification from "pages/Admin/ReviewNotfification/Home"

// 3. ATTENDANCE - /admin/attendance

// 4. TIME TABLE - /admin/time-table

// 5. Feedback - /admin/feedback/notifications

// 6. Upload Student Avatar - /admin/upload-student-avatar

// 7. Fees  - /admin/review/notifications
const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/dashboard/parent", component: <Dashboard /> },
  { path: "/dashboard/teacher", component: <Dashboard /> },
  { path: "/dashboard/admin", component: <Dashboard /> },
  //Utilities
  { path: "/external-link", component: <ExternalLinks /> },
  { path: "/media-preview", component: <MediaView /> },
  { path: "/handleroutes", component: <HandleRoutes /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/notifications", component: <Notifications /> },
  { path: "/create-notifications", component: <CreateNotifications /> },
  { path: "/feedback", component: <FeedBack /> },
  { path: "/feedback/create", component: <CreateFeedback /> },
  { path: "/feedback/chats", component: <ChatWindow /> },
  { path: "/timetable", component: <ViewTimeTable /> },
  {
    path: "/teacher/student/leave-report",
    component: <StudentLeaveReportTeacher />,
  },
  { path: "/paidfeesdetails", component: <PaidFeesDetails /> },
  { path: "/printpaidfeesdetails", component: <PrintRecipt /> },
  { path: "/fees/payment", component: <OnlineFess /> },
  { path: "/report/card", component: <ReportCard /> },
  { path: "/student-portfolio/report", component: <StudentPortfolioReport /> },
  { path: "/student/apply-leave", component: <StudentLeaveApply /> },
  { path: "/fill/remark", component: <RemarkHome /> },
  { path: "/student/Leave-report", component: <StudentLeaveReport /> },
  { path: "/teacher/attendance", component: <Attendance /> },
  { path: "/teacher/attendance-report", component: <AttendanceReport /> },
  { path: "/gallery", component: <Gallery /> },
  { path: "/document/repository", component: <DocHome /> },
  { path: "/document/repository/:id", component: <DocRepo /> },
  { path: "/student/holidays", component: <Holidays /> },
  { path: "/document/sub/:id/:repoId", component: <DocSub /> },
  { path: "/gallery/images", component: <ImageGallery /> },
  { path: "/student/gallery", component: <GalleryStud /> },
  { path: "/gallery-images/:id", component: <ListOfImages /> },
  { path: "/teacher/fill/marks", component: <ExamFillMarker /> },
  { path: "/class-test/marks", component: <ClassTestMarks /> },
  { path: "/class-test/marks/:id", component: <SingleClassTest /> },
  { path: "/subject-access-request", component: <AccessHome /> },
  { path: "/subject-access", component: <SubjectAccess /> },
  { path: "/subject-access-report", component: <SubjectAccessReport /> },
  { path: "/staff-profile", component: <TeacherProfile /> },
  { path: "/student-leave-report", component: <StudentLeave /> },
  { path: "/student-profile/pic", component: <StudentProfile /> },
  { path: "/student-profile", component: <StudentProfileApp /> },
  { path: "/fees", component: <Fees /> },
  { path: "/fees/details/:user_id/:id", component: <FeesDetails /> },
  { path: "/teacher/leave", component: <TeacherLeaveApp /> },
  { path: "/teacher/apply-leave", component: <LeaveRequest /> },
  { path: "/teacher/leave-history", component: <LeaveHistory /> },
  { path: "/visitor-home", component: <VisitorApp /> },
  { path: "/visitor-manually", component: <VisiorManullay /> },
  { path: "/visitors", component: <CheckoutHome /> },
  { path: "/calendar", component: <CalendarHome /> },
  { path: "/Assignment/home", component: <AssigmentHome /> },
  { path: "/create-assignment", component: <CreateAssignment /> },
  { path: "/students-assignment", component: <StudentsAssignment /> },
  { path: "/assignment-report", component: <AssignmentReport /> },
  { path: "/view-assignment/:id", component: <ViewAssignmentStundent /> },
  { path: "/class-test", component: <ClassTestReport /> },
  { path: "/student-assignment", component: <StudentAssignmentHome /> },
  { path: "/assignment", component: <AssignmentDeatils /> },
  {
    path: "/student-submitted-assignmet",
    component: <SubmittedAssignmentDeatils />,
  },
  { path: "/assignmet-remark/:id", component: <SubmitRemark /> },

  { path: "/submit-assignment/:id", component: <SubmitAssignment /> },

  { path: "/scan", component: <SM /> },
  // {path: "/Portfolio", component: <PortfilioHome/>},
  { path: "/teacher-portfolio-report", component: <TeacherPortfolioReport /> },
  // {path: "/create-portfolio", component: <CreatePortfolio/>},
  { path: "/LogSheet", component: <LogSheet /> },
  { path: "/log-time", component: <LogsheetTimetable /> },
  { path: "/log-sheet/:id", component: <LogSheetSubmit /> },
  { path: "/log-report", component: <LogSheetReport /> },
  { path: "/notes/upload", component: <NotesUpload /> },
  { path: "/student/notes", component: <StudentNotes /> },
  { path: "/student/leave", component: <StudentLeaveApp /> },
  { path: "/new/notifications", component: <NewNotifiationCenter /> },
  { path: "/student/leave", component: <StudentLeaveHome /> },
  { path: "/t", component: <TestApp /> },

  // ADMIN APP ROUTES --
  { path: "/schedule/notifications", component: <ScheduleNotification /> },
  { path: "/review/notifications", component: <ReviewNotification /> },

  {
    path: "*",
    component: <Comingsoon />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/visitor-scanner", component: <VisitorScanner /> },
  { path: "/report/card/details", component: <ReportResult /> },
  { path: "/feesdetails", component: <DownloadFees /> },
  { path: "/report", component: <StudentPortfolioDownload /> },
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/notification/center/app", component: <NotificationCenterPrisms /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
