import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { getAlbum } from "store/actions"
import { Link } from "react-router-dom"
import "../Dashboard/Dashboard.css"
import Spinners from "components/Common/Spinner"
import { replaceS3Url } from "components/Common/ReplaceS3Url"
import moment from "moment"
import "./Gallary.css"

const Gallery = () => {
  const { albumList, Loading } = useSelector(state => state.GallaryReducer)
  const [newAlbumList, setNewAlbumList] = useState([])
  const [isProcessing, setIsProcessing] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAlbum())
  }, [dispatch])

  useEffect(() => {
    if (albumList && albumList.length > 0) {
      setIsProcessing(true) // Start processing
      const tempArray = {}
      const groupedAlbums = []
      for (let i = 0; i < albumList.length; i++) {
        const album = albumList[i]
        if (!tempArray[album.id]) {
          tempArray[album.id] = {
            id: album.id,
            album_name: album.album_name,
            up_date: album.up_date,
            thumbnails: [],
            snaps: [],
          }
        }
        tempArray[album.id].thumbnails.push(album.thumbnail)
        tempArray[album.id].snaps.push(album.snap)
        if (i === albumList.length - 1 || albumList[i + 1].id !== album.id) {
          groupedAlbums.push(tempArray[album.id])
        }
      }
      setNewAlbumList(groupedAlbums)
      setIsProcessing(false)
    } else {
      setIsProcessing(false)
    }
  }, [albumList])

  if (Loading || isProcessing) {
    return (
      <div className="page-content">
        <Container>
          <Card>
            <CardBody className="text-center">
              <Spinners />
            </CardBody>
          </Card>
        </Container>
      </div>
    )
  }

  return (
    <div className="page-content">
      <Row>
        {newAlbumList && (
          newAlbumList.map((data, index) => {
            const dateNum = moment(data.up_date).format("DD/MM/YYYY")
            return (
              <Link
                className="col-6 col-md-4 col-xl-3"
                to="/gallery/images"
                state={{ id: data.id }}
                key={index}
              >
                <div className="p-1">
                  <div className="row border border-2 p-1 bg-white shadow rounded-3">
                    {data.thumbnails.map((thumb, index1) => (
                      <div className="col-6 p-1" key={index1}>
                        <div className="image-wrapper">
                          <img
                            src={
                              thumb !== ""
                                ? replaceS3Url(thumb)
                                : replaceS3Url(data.snaps[index1]) || ""
                            }
                            className="img-fluid square-image"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="text-center text-dark">
                    <p className="m-0 p-0">{data.album_name}</p>
                    <p className="mt-0 pt-0" style={{ fontSize: "8px" }}>
                      ({dateNum})
                    </p>
                  </div>
                </div>
              </Link>
            )
          })
        )}
      </Row>
    </div>
  )
}

export default Gallery
