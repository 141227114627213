import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ListGroup, ListGroupItem, Spinner, Container, Row, Col } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const InfiniteScroll = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false); 
  const targetRef = useRef(null);
  const limit = 10;

  const fetchData = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const response = await axios.get("https://dummyjson.com/posts", {
        params: {
          limit,
          skip: page * limit,
        },
      });
      setData((prevData) => [...prevData, ...response.data.posts]);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchData();
        }
      },
      { threshold: 1.0 }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => observer.disconnect(); 
  }, [targetRef, page]);

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={8}>
          <h1 className="text-center mb-4">Infinite Scroll Example</h1>

          {/* Display the list of data */}
          <ListGroup>
            {data.map((item) => (
              <ListGroupItem key={item.id} className="mb-2">
                <h5>{item.title}</h5>
                <p>{item.body}</p>
              </ListGroupItem>
            ))}
          </ListGroup>

          {/* Trigger element for API call */}
          <div
            ref={targetRef}
            className="text-center mt-4"
            style={{ height: "50px", background: "#f8f9fa" }}
          >
            {isLoading ? (
              <Spinner color="primary" />
            ) : (
              <p className="text-muted">Scroll down to load more</p>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default InfiniteScroll;
