import React from 'react';

const Message = ({ text }) => {
  const URL_REGEX = /(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]+)(?![\w\s.?&%=+~-]*["'])/;

  
    let finalText = '';
    const words = text.split(/\s+/); 

    for (const word of words) {
        const moduleRegex = new RegExp(/https?:\/\/[^\s]+/g); 
        const isExternalLink = moduleRegex.test(word);
        if (isExternalLink) {
            finalText += ` <a href="${word}" target="_blank" rel="noopener noreferrer">${word}</a>`;
        } else {
            finalText += ` ${word}`;
        }
    }
    const renderTextWithLinks = (text) => {
      const URL_REGEX = /(https?:\/\/[^\s]+|www\.[^\s]+)/g; // Add your URL regex
      const words = text.split(/(\s+|\n+)/); // Split text by spaces and newlines
      return words.map((part, index) => {
        const matches = part.match(URL_REGEX);
        if (matches) {
          let url = matches[0];
          if (url.startsWith("www.")) {
            url = "http://" + url;
          }
          const punctuationMatch = part.match(/\W+$/); // Extract trailing punctuation
          const trimmedUrl = punctuationMatch ? url.slice(0, -punctuationMatch[0].length) : url;
          return (
            <React.Fragment key={index}>
              <a
                style={{ color: 'blue', textDecoration: 'underline' }}
                href={trimmedUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {trimmedUrl}
              </a>
              {punctuationMatch ? punctuationMatch[0] : ""}
            </React.Fragment>
          );
        } else {
          return <React.Fragment key={index}>{part}</React.Fragment>;
        }
      });
    };
    return  <p
    style={{
      whiteSpace: 'pre-line',
      fontWeight: '400',
      letterSpacing: '0.7px',
      color: '#111',
    }}
  >
    {renderTextWithLinks(text)}
  </p>
};

export default Message;
