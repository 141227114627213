// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import {
  Container,
  FormGroup,
  Input,
  Label,
  Form,
  Button,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap"
import { createFeedback, getStaffList } from "store/actions"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const CreateFeedback = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const Subjects = ["School", "Web Site", "Parent Portal", "Others"]
  const { staffList, errors, successMsg } = useSelector(
    state => state.FeedBackReducer
  )
  useEffect(() => {
    if (errors && errors !== null) {
      toast.error(errors)
    }
    if (successMsg && successMsg !== null) {
      if (successMsg === "New conversation and thread created successfully") {
        navigate("/feedback")
      }
    }
  }, [errors, successMsg])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      selectTeach: "",
      selectSub: "",
      message: "",
    },
    validationSchema: Yup.object({
      selectTeach: Yup.string().required("Please Select Reciptent"),
      selectSub: Yup.string().required("Please Select Subject"),
      message: Yup.string().required("Please Enter your query or feedback"),
    }),
    onSubmit: values => {
      dispatch(
        createFeedback({
          to: values.selectTeach,
          subject: values.selectSub,
          message: values.message,
        })
      )
    },
  })

  useEffect(() => {
    dispatch(getStaffList())
  }, [])

  console.log(staffList)
  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <CardBody>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <FormGroup>
                <Label for="Selectteachers" className="form-label">
                  Select Recipient
                </Label>
                <Input
                  id="Selectteachers"
                  name="selectTeach"
                  type="select"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.selectTeach || ""}
                  invalid={
                    validation.touched.selectTeach &&
                    validation.errors.selectTeach
                      ? true
                      : false
                  }
                >
                  <option value={null}>Select Recipient</option>
                  <option value={170}>
                    {/* <span className="bg-danger text-white rounded px-2 py-1">
                      A
                    </span>{" "} */}
                    Admin
                  </option>

                  {staffList &&
                    staffList.length > 0 &&
                    staffList.map((staff, index) => {
                      return (
                        <option value={staff.user_id} key={index}>
                          {/* <span className="bg-primary text-white rounded px-2 py-1">
                            T
                          </span>{" "} */}
                          {staff.designation} - {staff.name}
                        </option>
                      )
                    })}
                </Input>
                {validation.touched.selectTeach &&
                validation.errors.selectTeach ? (
                  <FormFeedback type="invalid">
                    {validation.errors.selectTeach}
                  </FormFeedback>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label for="SubjectInput" className="form-label">
                  Select Subject
                </Label>
                <Input
                  id="SubjectInput"
                  name="selectSub"
                  type="select"
                  placeholder="Select Subject"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.selectSub || ""}
                  invalid={
                    validation.touched.selectSub && validation.errors.selectSub
                      ? true
                      : false
                  }
                >
                  <option value={null}>Select Subject</option>
                  {Subjects.map((subject, index) => (
                    <option value={subject} key={index}>
                      {subject}
                    </option>
                  ))}
                </Input>
                {validation.touched.selectSub && validation.errors.selectSub ? (
                  <FormFeedback type="invalid">
                    {validation.errors.selectSub}
                  </FormFeedback>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label for="queryMsg" className="form-label">
                  Enter Your Query/Feedback
                </Label>
                <Input
                  name="message"
                  type="textarea"
                  id="queryMsg"
                  className="form-control"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.message || ""}
                  invalid={
                    validation.touched.message && validation.errors.message
                      ? true
                      : false
                  }
                />
                {validation.touched.message && validation.errors.message ? (
                  <FormFeedback type="invalid">
                    {validation.errors.message}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup className="text-center">
                <Button
                  type="submit"
                  color="primary"
                  className="btn-rounded form-control"
                >
                  Submit
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default CreateFeedback
