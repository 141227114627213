import {
  GET_TEST_MARKS_SUCCESS,
  GET_TEST_MARKS,
  GET_TEST_MARKS_RESET,
  GET_TEST_MARKS_FAILED,
} from "./Constant"

export const GetTestStart = payload => ({
  type: GET_TEST_MARKS,
  payload,
})

export const GetTestSuccess = payload => ({
  type: GET_TEST_MARKS_SUCCESS,
  payload,
})

export const GetTestFailure = payload => ({
  type: GET_TEST_MARKS_FAILED,
  payload,
})

export const GetTestReset = () => ({
  type: GET_TEST_MARKS_RESET,
})
