import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentAcadid } from "store/actions"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  FormGroup,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import axios from "axios"
import Spinners from "components/Common/Spinner"

const LogReport = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = JSON.parse(localStorage.getItem("Token"))
  const [Loading, setLoading] = useState(false)
  const [date, setDate] = useState("")
  const [leave_applied_date, SetLeaveAppliedDate] = useState()
  const [endDate, setEndDate] = useState("")
  const [reason, setLeaveReason] = useState("")
  const [attachment, setAttachment] = useState("")
  const [errors, setErrors] = useState({})

  const { acadID } = useSelector(state => state.CommonReducer)

  const [checkBox, setCheckBox] = useState({
    webCheck: 1,
    appCheck: 0,
    emailCheck: 0,
    smsCheck: 1,
  })

  useEffect(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0")
    const day = String(currentDate.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    setDate(formattedDate)
    SetLeaveAppliedDate(formattedDate)
  }, [])

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
  }, [dispatch])

  const validateFields = () => {
    const errors = {}
    if (!date) errors.date = "Start date is required"
    if (!endDate) errors.endDate = "End date is required"
    if (!reason) errors.reason = "Leave reason is required"
    return errors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const fieldErrors = validateFields()
    if (Object.keys(fieldErrors).length > 0) {
      setErrors(fieldErrors)
    } else {
      setErrors({})
      setLoading(true)
      const formData = new FormData()
      formData.append("leave_applied_date", leave_applied_date)
      formData.append("leave_start_date", date)
      formData.append("leave_end_date", endDate)
      formData.append("acad_id", acadID.acadid)
      formData.append("leave_reason", reason)
      formData.append("files", attachment)
      // formData.append("destinations", ["sms", "email", "app", "web"])
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/studentLeave/addLeave",
        data: formData,
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setLoading(false)
        toast.success(res.data.message)
        navigate("/student/Leave-report")
      })
    }
  }

  const handleFieldChange = (field, value) => {
    if (field === "date") setDate(value)
    if (field === "endDate") setEndDate(value)
    if (field === "reason") setLeaveReason(value)

    if (errors[field]) {
      setErrors(prevErrors => {
        const updatedErrors = { ...prevErrors }
        delete updatedErrors[field]
        return updatedErrors
      })
    }
  }

  // const handleCheckBox = inputCheck => {
  //   setCheckBox(prevState => ({
  //     ...prevState,
  //     [inputCheck.target.name]: inputCheck.target.checked ? 1 : 0,
  //   }))
  // }



  return (
    <div className="page-content">
      <Container>
        <Card className="shadow-lg rounded-lg">
          {Loading ? (
            <Spinners />
          ) : (
            <CardBody>
              <h1
                className="text-center mb-4 fs-3"
                style={{ color: "#3c3c3c" }}
              >
                Apply Leave
              </h1>
              {/* <FormGroup>
                <div className="w-100 d-flex justify-content-around px-0 mx-0">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="webCheck"
                      onChange={e => handleCheckBox(e)}
                      type="checkbox"
                      checked={checkBox.webCheck ===1}
                      id="WebSelect"
                    />
                    <label className="form-check-label" htmlFor="WebSelect">
                      Web
                    </label>
                  </div>

                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="smsCheck"
                      onChange={e => handleCheckBox(e)}
                      type="checkbox"
                      checked={checkBox.smsCheck === 1}
                      id="SmsSelect"
                    />
                    <label className="form-check-label" htmlFor="SmsSelect">
                      Sms
                    </label>
                  </div>

                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="appCheck"
                      onChange={e => handleCheckBox(e)}
                      type="checkbox"
                      id="AppSelect"
                      
                    />
                    <label className="form-check-label" htmlFor="AppSelect">
                      App
                    </label>
                  </div>

                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="emailCheck"
                      onChange={e => handleCheckBox(e)}
                      type="checkbox"
                      id="emailSelect"
                    />
                    <label className="form-check-label" htmlFor="emailSelect">
                      Email
                    </label>
                  </div>
                </div>
              </FormGroup> */}

              <Row className="mb-4">
                <Col xl="12" md="12" sm="12">
                  <Label className="fw-4">Start Date</Label>
                  <span className="text-danger">*</span>
                  <Input
                    type="date"
                    value={date}
                    onChange={e => handleFieldChange("date", e.target.value)}
                    className="form-control"
                    style={{ borderRadius: "4px", padding: "10px" }}
                  />
                  {errors.date && (
                    <div
                      className="text-danger mt-1"
                      style={{ fontSize: "14px" }}
                    >
                      {errors.date}
                    </div>
                  )}
                </Col>

                <Col xl="12" md="12" sm="12">
                  <Label className="fw-4 mt-3">End Date</Label>
                  <span className="text-danger">*</span>
                  <Input
                    type="date"
                    value={endDate}
                    onChange={e => handleFieldChange("endDate", e.target.value)}
                    className="form-control"
                    style={{ borderRadius: "4px", padding: "10px" }}
                  />
                  {errors.endDate && (
                    <div
                      className="text-danger mt-1"
                      style={{ fontSize: "14px" }}
                    >
                      {errors.endDate}
                    </div>
                  )}
                </Col>

                <Col xl="12" md="12" sm="12">
                  <Label className="fw-4 mt-3">Leave Reason</Label>
                  <span className="text-danger">*</span>
                  <Input
                    type="textarea"
                    value={reason}
                    onChange={e => handleFieldChange("reason", e.target.value)}
                    className="form-control"
                    style={{ borderRadius: "4px", padding: "10px" }}
                  />
                  {errors.reason && (
                    <div
                      className="text-danger mt-1"
                      style={{ fontSize: "14px" }}
                    >
                      {errors.reason}
                    </div>
                  )}
                </Col>

                <Col xl="12" md="12" sm="12">
                  <Label className="fw-4 mt-3">Attachment</Label>
                  <Input
                    type="file"
                    className="form-control"
                    onChange={e => setAttachment(e.target.files[0])}
                    style={{ borderRadius: "4px", padding: "10px" }}
                  />
                </Col>

                <div className="d-flex justify-content-center">
                  <Button
                    color="primary"
                    className="mt-4 btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </Row>
            </CardBody>
          )}
        </Card>
        <ToastContainer />
      </Container>
    </div>
  )
}

export default LogReport
