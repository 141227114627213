import React, { useEffect, useState, useMemo } from "react"
import { Col, Row, Container, CardBody, Label, Badge, Card } from "reactstrap"
import axios from "axios"
import { getCurrentAcadid } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import Spinners from "components/Common/Spinner"
import { Link } from "react-router-dom"
import { GetTestStart } from "store/TeacherApp/Exam/Action"
import { toast, ToastContainer } from "react-toastify"
const Home = () => {
  const token = JSON.parse(localStorage.getItem("Token"))
  const { errors, TestData } = useSelector(state => state.ClassTestReducer)
  const dispatch = useDispatch()
  const [div, setDiv] = useState([])
  const [loading, setLoading] = useState(false)
  const [div_id, setDiv_id] = useState(null)
  const [TestData2, setTestData] = useState([])
  const { acadID } = useSelector(state => state.CommonReducer)

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
  }, [])

  //
  useEffect(() => {
    const getClassDiv = async () => {
      try {
        await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/examfillmarks/get-classdiv-list",
          data: {
            acad_id: acadID.acadid,
          },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setDiv(res.data.data)
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (acadID.acadid) {
      getClassDiv()
    }

    if (div_id != null) {
      dispatch(
        GetTestStart({
          classdiv_id: div_id,
        })
      )
    }
  }, [acadID, div_id])

  const handlePublish = async (status, id) => {
    try {
      console.log(status)
      console.log(id)
      const data = {
        status: status,
        classtest_id: id,
        classdiv_id: parseInt(div_id),
        destinations:["sms","email","app","web"],
        publish_mode: "app",
      }

      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/classtestfillmarks/set-class-test-status",
        data: data,
        headers: {
          Authorization: token,
        },
      })
        .then(res => {
          console.log(res.status)
          console.log(res.data)
          console.log(res.data.message)
          toast.success(res.data.message)
          dispatch(
            GetTestStart({
              classdiv_id: div_id,
            })
          )
        })
        .catch(error => {
          console.log(error.message)
          toastr.error(error.message)
        })
    } catch (err) {}
  }

  return (
    <div className="page-content">
      <Container>
        <Card>
          <CardBody>
            <h1 className="text-center mb-4 fs-3" style={{ color: "#3c3c3c" }}>
              Class Test Marks
            </h1>{" "}
            <Row className="mb-4">
              <Col xl="12" md="12" sm="12">
                <select
                  className="form-select rounded"
                  aria-label="Default select example"
                  onChange={e => setDiv_id(e.target.value)}
                >
                  <option>Select Class</option>

                  {div &&
                    div.length > 0 &&
                    div.map((item, index) => (
                      <option key={item.id} value={item.id}>
                        {item.div_name}
                      </option>
                    ))}
                </select>
              </Col>
            </Row>
            {loading ? (
              <Spinners />
            ) : (
              <div>
                {TestData &&
                  TestData.length > 0 &&
                  TestData.map((item, index) => {
                    return (
                      <div key={index}>
                        <Card className="shadow-sm p-4 rounded mt-4">
                          <Row>
                            <div className="d-flex flex-row justify-content-between">
                              <span
                                style={{ fontWeight: 600 }}
                                className="mt-3 w-50"
                              >
                                Test Date
                              </span>
                              <span className="mt-3 w-50">
                                {item.Test_Date}
                              </span>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                              <span
                                style={{ fontWeight: 600 }}
                                className="mt-3 w-50"
                              >
                                Test Name
                              </span>
                              <span className="mt-3 w-50">{item.testName}</span>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                              <span
                                style={{ fontWeight: 600 }}
                                className="mt-3 w-50"
                              >
                                Out Of Marks
                              </span>
                              <span className="mt-3 w-50">
                                {item.outOfMarks}
                              </span>
                            </div>
                          </Row>
                          <Row>
                            <div className="d-flex flex-row justify-content-between mt-3">
                              {item.status_is_publish === 0 ? (
                                <Badge
                                  color="success"
                                  className="p-2 rounded"
                                  onClick={e => handlePublish(1, item.test_id)}
                                >
                                  Publish Test
                                </Badge>
                              ) : (
                                <Badge
                                  color="warning"
                                  className="p-2 rounded"
                                  onClick={e => handlePublish(0, item.test_id)}
                                >
                                  UnPublish Test
                                </Badge>
                              )}
                              <Link
                                className="d-flex justify-content-end"
                                to={`/class-test/marks/${item.test_id}`}
                                state={{
                                  classdiv_id: item.class_divid,
                                  subject_id: item.subjectId,
                                  acad_id: item.acad_id,
                                  test_id: item.test_id,
                                  test_name: item.testName,
                                  test_date: item.Test_Date,
                                  subject_name: item.subjectName,
                                  outOfMarks: item.outOfMarks,
                                  className: item.divisionName,
                                  status_is_publish: item.status_is_publish,
                                }}
                              >
                                <Badge
                                  color="primary"
                                  className="p-2 rounded d-flex align-items-center"
                                >
                                  Exam Fill Marks
                                </Badge>
                              </Link>
                            </div>
                          </Row>
                        </Card>
                      </div>
                    )
                  })}
              </div>
            )}
          </CardBody>
        </Card>
        <ToastContainer />
      </Container>
    </div>
  )
}

export default Home
