import moment from "moment/moment"
import React, { useEffect, useState } from "react"
import axios from "axios"
import {
  Button,
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge,
} from "reactstrap"

const HolidayCalendar = () => {
  const [report, setReport] = useState([])
  const [loader, setLoader] = useState(false)

  const getReport = async () => {
    try {
      setLoader(true)
      const data = {
        sid: localStorage.getItem("sid"),
      }
      const response = await axios({
        method: "post",
        url: "https://marcopolo.prisms.in/api/holiday/details",
        data,
      })
      function combine(arr) {
        var combined = arr.reduce(function (result, item) {
          var current = result[item.occasion]

          result[item.occasion] = !current
            ? item
            : {
                id: item.id,
                occasion: current.occasion,
                details: item.details,
                startdate: current.startdate,
                endDate: item.startdate,
              }

          return result
        }, {})

        return Object.keys(combined).map(function (key) {
          return combined[key]
        })
      }

      const result = combine(response.data)
      setReport(result)
    } catch (error) {}
  }

  useEffect(() => {
    getReport()
  }, [])

  const [currentMonth, setCurrentMonth] = useState(new Date())

  const getMonthString = monthIndex => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    return months[monthIndex]
  }

  const getFilteredHolidaysForMonth = (month, year) => {
    return report.filter(holiday => {
      const holidayDate = new Date(holiday.startdate)
      return (
        holidayDate.getMonth() === month && holidayDate.getFullYear() === year
      )
    })
  }

  const handleMonthChange = direction => {
    const newDate = new Date(currentMonth)
    if (direction === "prev") {
      newDate.setMonth(currentMonth.getMonth() - 1)
    } else if (direction === "next") {
      newDate.setMonth(currentMonth.getMonth() + 1)
    }
    setCurrentMonth(newDate)
  }

  const holidaysForMonth = getFilteredHolidaysForMonth(
    currentMonth.getMonth(),
    currentMonth.getFullYear()
  )

  // console.log(report)

  return (
    <div className="page-content">
      <Container className="">
        <Row className="justify-content-center">
          <Col lg={12} md={6} sm={4}>
            <Card className="shadow-lg rounded">
              <CardHeader className="text-center">
                <Button
                  color="primary"
                  className="mr-4 px-4"
                  onClick={() => handleMonthChange("prev")}
                  size="sm"
                >
                  <i className="fas fa-chevron-left"></i>
                </Button>
                <span className="font-weight-bold fs-5 m-3">
                  {getMonthString(currentMonth.getMonth())}{" "}
                  {currentMonth.getFullYear()}
                </span>
                <Button
                  color="primary"
                  className="ml-3 px-4"
                  onClick={() => handleMonthChange("next")}
                  size="sm"
                >
                  <i className="fas fa-chevron-right"></i>
                </Button>
              </CardHeader>
                <ListGroup>
                  {holidaysForMonth.length > 0 ? (
                    holidaysForMonth.map(holiday => (
                      <ListGroupItem
                        key={holiday.id}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <span className="font-weight-bold fw-medium fs-6 text-dark">
                          {holiday.occasion}
                        </span>
                        <div>
                          <Badge color="primary" pill>
                            {moment(holiday.startdate).format("DD-MM-YYYY")}
                          </Badge>
                          {holiday.endDate ? (
                            <>
                              <st></st> To <st></st>
                            </>
                          ) : null}
                          <Badge color="success" pill>
                            {holiday.endDate
                              ? moment(holiday.endDate).format("DD-MM-YYYY")
                              : null}
                          </Badge>
                        </div>
                      </ListGroupItem>
                    ))
                  ) : (
                    <ListGroupItem className="text-center fs-5">
                      No holidays this month!
                    </ListGroupItem>
                  )}
                </ListGroup>
              <CardFooter className="text-center text-muted">
                Holidays calendar
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HolidayCalendar
