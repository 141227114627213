import React, { useEffect, useState } from "react"
import { Container, Col, Row, Card, CardBody } from "reactstrap"
import axios from "axios"
import moment from "moment"

const CardD = ({ data }) => {
  return (
    <Card className="shadow p-3 mb-4 rounded">
      <Row>
        {data.map((item, index) => (
          <Col key={index} xs={12} md={6} className="mb-2">
            <div className="d-flex align-items-start">
              <p className="fw-bold text-dark fw-5 mb-0 me-2 w-50">
                {item.title}:
              </p>
              <p className="mb-0 w-50 fw-5 text-dark">{item.value || "N/A"}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Card>
  )
}

const StudentLeaveReport = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const token = JSON.parse(localStorage.getItem("Token"))

  const getLeaveData = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "GET",
        url: "https://marcopolo.prisms.in/api/studentLeave/reports/teacher",
        headers: {
          Authorization: token,
        },
      })
      setData(response.data)
    } catch (error) {
      console.error("Error fetching leave data:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getLeaveData()
  }, [])

  return (
    <div className="page-content">
      <Container>
        <Card shadow-lg rounded-lg>
          <CardBody>
            <h1 className="text-center mb-4 fs-3" style={{ color: "#3c3c3c" }}>
              Student Leave Report
            </h1>
            <Row className="">
              <Col>
                {loading ? (
                  <p>Loading...</p>
                ) : data.length > 0 ? (
                  data.map((leave, index) => (
                    <CardD
                      key={index}
                      data={[
                        {
                          title: "Student Name",
                          value: leave.fname + " " + leave.lname,
                        },
                        { title: "Class", value: leave.div_name },
                        {
                          title: "Leave Applied Date",
                          value: moment(leave.leave_applied_date).format("DD-MM-YYYY"),
                        },
                        {
                          title: "Leave Start Date",
                          value: moment(leave.leave_start_date).format("DD-MM-YYYY"),
                        },
                        {
                          title: "Leave End Date",
                          value: moment(leave.leave_end_date).format("DD-MM-YYYY"),
                        },
                        // {
                        //   title: "Half Day",
                        //   value: leave.halfDay ? "Yes" : "No",
                        // },
                        { title: "Leave Reason", value: leave.leave_reason },
                      ]}
                    />
                  ))
                ) : (
                  <p>No data available</p>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default StudentLeaveReport
// {loading ? (
//   <p>Loading...</p>
// ) : data.length > 0 ? (
//   data.map((leave, index) => (
//     <CardD
//       key={index}
//       data={[
//         { title: "First Name", value: leave.fname },
//         { title: "Last Name", value: leave.lname },
//         { title: "Division", value: leave.div_name },
//         {
//           title: "Leave Applied Date",
//           value: leave.leave_applied_date,
//         },
//         { title: "Leave Start Date", value: leave.leave_start_date },
//         { title: "Leave End Date", value: leave.leave_end_date },
//         { title: "Half Day", value: leave.halfDay ? "Yes" : "No" },
//         { title: "Leave Reason", value: leave.leave_reason },
//       ]}
//     />
//   ))
// ) : (
//   <p>No data available</p>
// )}
