import React, { useEffect, useMemo, useState } from "react"
import classnames from "classnames"
import "./ViewTimeTable.css"
import { Card, CardBody, Col, Input, Row, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import axios from "axios"
import {
  getCurrentAcadid,
  getTimeTable,
  getTimeTableDays,
  timeTableHideCol,
} from "store/actions"
import { useSelector } from "react-redux"
import moment from "moment"

const ViewTimeTable = () => {
  const dispatch = useDispatch()
  const sid = localStorage.getItem("sid")
  const userId = localStorage.getItem("user_id")

  const { Loading, timeTableDays, timeTable, HideCol } = useSelector(
    state => state.TimeTableReducer
  )
  const { acadID } = useSelector(state => state.CommonReducer)
  const [selectedDay, setSelectedDay] = useState("")
  const [showDay, setShowDay] = useState("")
  const [staffName , setStaffName] = useState("")

  const date = new Date()
  const currectDayCount = date.getDay()

  const getTeachername = async () => {
    await axios({
      method: "post",
      url: "https://marcopolo.prisms.in/api/tscommon/staffname",
      data: {
        sid: sid,
        staffid: userId,
      },
    })
      .then(response => {
        console.log(response.data[0])
        setStaffName(response.data[0].first_name + " " + response.data[0].last_name)
      })
      .catch(error => {
        console.log(error)
      })
  }


  const handleGetTimeTable = day => {
    dispatch(getTimeTable({ dayid: day.id }))
    setSelectedDay(day.id)
    setShowDay(day.day)
  }

  const daysElements = useMemo(
    () =>
      timeTableDays && Array.isArray(timeTableDays)
        ? timeTableDays.map((data, index) => (
            <div
              key={index}
              className={classnames("days", {
                selectedDay: selectedDay === data.id,
              })}
              onClick={() => handleGetTimeTable(data)}
            >
              {data.day.slice(0, 3)}
            </div>
          ))
        : null,
    [timeTableDays, selectedDay, handleGetTimeTable]
  )

  const uniqueObjects = [
    ...new Map(timeTable.map(item => [item.lecture_Time, item])).values(),
  ]

  const timetableElements = useMemo(
    () =>
      uniqueObjects &&
      Array.isArray(uniqueObjects) &&
      uniqueObjects.length > 0 ? (
        uniqueObjects.map((data, index) => (
          <Link to={`/log-sheet/${data.dayid}`} key={index}>
            <Row className="">
              <Col className="col-2 p-0">
                <div className="leftNumberContainer">
                  <span className="leftNumber">{index + 1}</span>
                </div>
              </Col>
              <Col className="col-10 border border-bottom py-2 ps-1">
                <div className="rightContainer">
                  <div className="lectureData">
                    <p className="m-0 time text-dark fw-medium">
                      {data.teacherid ? data.subject : data.lecture_Time}
                      {data.teacherid
                        ? null
                        : data.period
                        ? ` (${data.period})`
                        : ""}
                    </p>

                    {HideCol && HideCol.subjecthide !== "1" && (
                      <p className="m-0 lecture">{data.value}</p>
                    )}
                    {HideCol && HideCol.subjecthide !== "1" && (
                      <p className="m-0 time">{data.teacher}</p>
                    )}
                    {HideCol && HideCol.subjecthide !== "1" && (
                      <p className="m-0 lecture">
                        {data.teacherid && data.lecturetime}
                      </p>
                    )}
                    {HideCol && HideCol.teacherhide !== "1" && (
                      <p className="m-0 teacherName">
                        {data.teacherid ? data.div_name : data.teachersName}
                      </p>
                    )}
                  </div>
                  {data.meeting_ID && data.meeting_ID !== "" && (
                    <div className="meetingID">
                      <a
                        href={data.meeting_ID}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fas fa-video" />
                      </a>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Link>
        ))
      ) : (
        <p className="text-center">
          No timetable available for the selected day.
        </p>
      ),
    [timeTable]
  )

  useEffect(() => {
    if (Array.isArray(timeTableDays) && timeTableDays.length > 0) {
      handleGetTimeTable({
        id: currectDayCount,
        day: moment(date).format("dddd"),
      })
    }
  }, [timeTableDays])

  useEffect(() => {
    dispatch(getTimeTableDays())
    dispatch(timeTableHideCol())
  }, [dispatch])

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    } else {
      toast.error("SID missing")
    }
    getTeachername()
  }, [])

  return (
    <div className="page-content px-0 bg-white" style={{ paddingTop: "62px" }}>
      <Card>
        <CardBody>
          <div className="timeTableContainer">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <span className="mt-3 fs-5 text-start text-dark">Name:</span>
              <span className="mt-1 fs-5 text-dark">{staffName}</span>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <span className="mt-3 fs-5 text-start text-dark">Date:</span>
              <span className="mt-1 fs-5 text-dark">
                <Input type="date" />
              </span>
            </div>
            <div className="dayTableContainer position-fixed w-100">
              {daysElements}
            </div>
            <div></div>
            <div className="pt-5">
              <hr className="mt-2" />
              <p className="text-primary m-2 fs-5">{showDay}</p>
              <hr className="mt-2 mb-0" />
            </div>
            {Loading ? (
              <div
                className="w-100 d-flex flex-column align-items-center justify-content-center"
                style={{ height: "65vh" }}
              >
                <Spinner className="mt-4 text-primary" />
              </div>
            ) : (
              <div className="subContainer">{timetableElements}</div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default ViewTimeTable
