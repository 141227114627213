import * as types from "./Constant";

const initialState = {
  errors: "",
  loading: false,
  TestData: [],
  isPublished: false,
  UnPublished: false,
};

const ClassTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TEST_MARKS:
      return {
        ...state,
        loading: true,
      };

    case types.GET_TEST_MARKS_SUCCESS:
      return {
        ...state,
        loading: false,
        TestData: action.payload, 
      };

    case types.GET_TEST_MARKS_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload, 
      };

    case types.GET_TEST_MARKS_RESET:
      return initialState; 

    default:
      return state;
  }
};

export default ClassTestReducer;